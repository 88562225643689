
import { defineComponent, onMounted } from "vue";
import KTTableWidgetTaxDepreciation from "@/components/widgets/tables/WidgetTaxDepreciation.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetTaxDepreciation,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Tax Depreciation", ["Financial Statements","Income Statements"]);
    });
  },
});
