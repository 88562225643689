
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Information1 from "@/components/dropdown/Information1.vue";
import ElCurrencyInput from "@/components/CurrencyInput.vue";
import { ElLoading } from "element-plus";
import moment from "moment";
import { Action } from "vuex-module-decorators";
import { object } from "yup/lib/locale";
import ElInfo from "@/components/ElInfo.vue";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";

export default defineComponent({
name: "TaxDepreciationNew",
components: {
  Form,
  ElCurrencyInputNoSymbol,
},
async mounted() {
  this.init();
  this.initMonths();
},
data() {
  const schema = Yup.object({});
  
  const months = [
      "Jan",
      "Feb",
      "Mac",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
  ];

  const statements = {
      incomeTaxZakat: {
          label: "Income Tax/Zakat",
          value: [],
          total:0,
      },
      depreciation: {
          label: "Depreciation",
          child: {
            planetMachinery: {
              label: "Planet & Machinery (14%)",
              price: [],
              rate: "14%",
              value: [],
              total:0,
            },
            officeEquipment: {
              label: "Office Equipment/Furniture (10%)",
              price: [],
              rate: "10%",
              value: [],
              total:0,
            },
            motorVehicle: {
              label: "Motor Vehicle (20%)",
              price: [],
              rate: "20%",
              value: [],
              total:0,
            },
          },
      },
      totalDepreciation: {
          label: "Total",
          value: [],
          total:0,
      },
  };

  return {
    schema:schema,
    months,
    statements,
  };
},
setup() {
  return {};
},
methods: {
  init() {
    setCurrentPageBreadcrumbs("Tax and Depreciation", ["Financial Statements","Income Statement"]);
  },
  onSubmit(values){
    console.log(values);
  },

  initMonths() {
      for (let index = 0; index < this.months.length; index++) {
          this.statements.totalDepreciation.value.push(0);
          this.statements.incomeTaxZakat.value.push(0);
      }

      for (let key of Object.keys(this.statements.depreciation.child)) {
          let depreciationChild = this.statements.depreciation.child;
          for (let index = 0; index < this.months.length; index++) {
              depreciationChild[key].value.push(0);
          }
      }
  },

  calculateTotalDepreciation() {

      this.statements.incomeTaxZakat.total = this.statements.incomeTaxZakat.value.reduce((sum,item) => sum + item);

      for (let i = 0; i < this.statements.totalDepreciation.value.length; i++) {
        this.statements.totalDepreciation.value[i] = 0;
      }

      for (let keyChild of Object.keys(this.statements.depreciation.child)) {
        let depreciationChild = this.statements.depreciation.child[keyChild];
          let stateChildValue = depreciationChild.value;

          depreciationChild.total = stateChildValue.reduce((sum,item) => sum + item);

          for (let i = 0; i < this.statements.totalDepreciation.value.length; i++) {
            this.statements.totalDepreciation.value[i] += stateChildValue[i];
          }
      }
  },
},
});
